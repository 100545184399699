
































































import { computed, defineComponent, watch } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { intlFormat } from "date-fns";
import { mapActions } from "vuex";
import { reserveerApi } from "@/lib/backend";
import { buttonVariants } from "@/lib/pfg/components/button";
import { labelVariants } from "@/lib/pfg/components/label";
import { useRoosterStore } from "@/pinia/rooster";
import store from "@/store/store";

export default defineComponent({
	setup() {
		const roosterStore = useRoosterStore();

		const { data: roosters } = useQuery({
			queryKey: ["roosters", "all"] as const,
			queryFn: async (context) =>
				await reserveerApi.roosters
					.getRoosters({ signal: context.signal })
					.then((response) => response.data),
			initialData: [] as Awaited<ReturnType<typeof reserveerApi.roosters.getRoosters>>["data"],
		});
		const published = computed(() =>
			roosters.value.find((rooster) => rooster.status === "gepubliceerd"),
		);
		const concept = computed(() => roosters.value.find((rooster) => rooster.status === "concept"));
		const selected = computed(() =>
			roosters.value.find((rooster) => rooster.id === roosterStore.id),
		);

		watch(
			roosters,
			() => {
				if (!concept.value || !!roosterStore.id) {
					return;
				}

				roosterStore.id = concept.value.id;
			},
			{ immediate: true },
		);

		watch(
			() => roosterStore.id,
			(value) => {
				if (value) {
					store.dispatch("rooster/getRooster", value);
				}
			},
			{ immediate: true },
		);

		return {
			intlFormat,
			labelVariants,
			buttonVariants,
			roosterStore,
			published,
			concept,
			selected,
		};
	},
	methods: mapActions("modal", ["openModal"]),
});

<template>
	<div>
		<div class="pg_content a_max-width-1200 a_padding-left-10">
			<router-link
				to="/groepsles-planning"
				class="button v_transparent v_has-icon-left v_icon-size-12 v_smallest"
				>Terug naar overzicht
				<icon :icon-id="'icon_arrow-left'"></icon>
			</router-link>
		</div>
		<rooster-bar />
		<section class="pg_content a_margin-top-40">
			<div class="pg_content_container a_max-width-1200">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<h1 class="s_elem-has-icon-left a_fill_default">
							{{ groepsles.naam }}
							<icon :icon-id="'icon_groepsles'"></icon>
						</h1>
						<!-- eslint-disable-next-line vue/no-v-html -->
						<div class="pg_header_lesson_description" v-html="groepsles.beschrijving"></div>
					</header>
					<hr />
				</div>
			</div>
		</section>
		<section class="pg_content">
			<div class="pg_content_container a_max-width-1200">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_top">
							<div class="pg_header_top_left">
								<div class="pg_header_heading">
									<h2>Les toevoegen</h2>
									<!-- <infoDropdown>
                                        Etiam porta sem malesuada magna mollis euismod. Maecenas faucibus mollis interdum.
                                    </infoDropdown>-->
								</div>
							</div>
							<div class="pg_header_top_right">
								<button
									:disabled="rooster.status === 'gepubliceerd'"
									class="button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10"
									@click="
										openOverlay({
											name: 'groepsles-inplannen',
											data: { editMode: false },
										})
									"
								>
									Les inplannen
									<icon :icon-id="'icon_plus'"></icon>
								</button>
							</div>
						</div>
					</header>
				</div>
			</div>
		</section>
		<section class="pg_list a_margin-top-60" data-list="groepsles-detail">
			<div class="pg_list_container">
				<div class="pg_list_heading">
					<div class="pg_list_heading_left">
						<h2>Planning</h2>
						<p class="a_text_brand_primary-lighter">
							<span
								>{{ filteredRooster.length }} geplande les{{
									filteredRooster.length > 1 ? "sen" : ""
								}}</span
							>
						</p>
					</div>
					<div class="pg_list_heading_right">
						<span class="a_text_brand_primary-lighter">
							<label>filter per vestiging</label>
						</span>
						<select v-model="branch" class="pg_vestiging-select">
							<option value="all">Alle vestigingen</option>
							<option
								v-for="(item, i) in sortedVestigingen"
								:key="`vestiging-select-${i}`"
								:value="item"
							>
								{{ item.naam }}
							</option>
						</select>
					</div>
				</div>
				<div class="pg_list_header">
					<div data-t="header-dag">Dag:</div>
					<div data-t="header-tijd">Tijd:</div>
					<div data-t="header-locatie">Locatie:</div>
					<div data-t="header-zaal">Zaal:</div>
					<div data-t="header-plaatsen">Plaatsen:</div>
					<div data-t="header-coach">Trainer:</div>
					<div data-t="header-type">Type:</div>
					<div data-t="header-weeks">&nbsp;</div>
					<!--<div data-t="header-resterend">Resterend:</div>-->
					<div data-t="header-buttons"></div>
				</div>
				<p v-if="!filteredRooster.length">
					Er zijn nog geen lessen ingepland voor {{ groepsles.naam }}
					{{ branch === "all" ? "" : "en " + branch.naam }}
				</p>
				<div v-for="les in filteredRooster" :key="`rooster-item-${les.id}`" class="pg_list_item">
					<div data-t="item-dag">{{ les.dag }}</div>
					<div data-t="item-tijd">{{ les.tijd }} - {{ getEndTime(les.tijd, les.duur) }}</div>
					<div data-t="item-locatie">
						{{ getVestigingById(les.vestigingId).naam }}
					</div>
					<div data-t="item-zaal">
						{{ getZaalById(les.vestigingId, les.zaalId).naam }}
					</div>
					<div data-t="item-plaatsen">{{ les.plekken }}</div>
					<div data-t="item-coach">
						<div class="pg_avatar v_smallest">
							<!--<figure class="pg_avatar_image" style="background-image: url(static/img/trainers/2.jpg);"></figure>-->
							<div class="pg_avatar_name">
								{{ getTrainerById(les.trainerId).naam }}
							</div>
						</div>
					</div>
					<div data-t="item-type">
						<icon v-if="les.itemType === 'virtual'" title="virtueel" icon-id="icon_virtual" />
						<icon v-else-if="les.itemType === 'buiten'" title="buiten" icon-id="icon_outside" />
						<icon v-else title="groepsles" icon-id="icon_live" />
					</div>
					<!-- <div data-t="item-weeks">
                        <span v-if="les.range && les.range.startWeek && les.range.eindWeek">
                            {{les.range.startWeek}}-{{les.range.eindWeek}}
                        </span>
                    </div>-->

					<!--<div data-t="item-resterend">{{ getTimesLeft(les.range.startWeek, les.range.eindWeek, les.dag) }}</div>-->
					<div data-t="item-buttons">
						<button
							v-if="!les.range"
							class="button v_brand_white v_is-icon v_icon-size-16 a_no-pointer-events a_fill_brand_primary-lighter a_margin-bottom-0"
						>
							<icon :icon-id="'icon_recurring'"></icon>
						</button>
						<div class="pg_divider-inline"></div>
						<button
							:disabled="rooster.status === 'gepubliceerd'"
							class="button v_brand_white v_is-icon v_icon-size-16 v_smaller a_fill_brand_secondary"
							@click="openModal({ name: 'groepsles-verwijderen', data: les })"
						>
							<icon :icon-id="'icon_cross'"></icon>
						</button>
						<button
							:disabled="rooster.status === 'gepubliceerd'"
							class="button v_brand_white v_is-icon v_icon-size-16 v_smaller a_fill_brand_secondary"
							@click="openLesEdit(les)"
						>
							<icon :icon-id="'icon_edit2'"></icon>
						</button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import RoosterBar from "@/components/parts/RoosterBar.vue";

export default {
	name: "GroepslesDetail",
	components: { RoosterBar },
	props: {
		id: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			days: ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"],
			branch: "all",
		};
	},
	computed: {
		...mapState("groepsles", ["groepsles"]),
		...mapState("rooster", ["rooster"]),
		...mapState("trainer", ["trainers"]),
		...mapState("vestiging", ["vestigingen"]),
		...mapState("branches", {
			branches: "items",
		}),
		filteredRooster() {
			// compare helper function
			function compare(x, y) {
				if (x === y) {
					return 0;
				}
				return x > y ? 1 : -1;
			}

			if (!this.rooster || !this.rooster.roosterItems) return [];
			// first filter by lessons only for this groeples
			return (
				this.rooster.roosterItems
					.filter((les) => les.groepslesId === this.groepsles.id)
					.filter((les) => {
						if (this.branch === "all") return true;
						return les.vestigingId === this.branch.id;
					})
					// sort first by day then by les start
					.sort((a, b) => {
						const dayA = this.days.indexOf(a.dag);
						const dayB = this.days.indexOf(b.dag);
						if (dayA !== dayB) {
							return compare(dayA, dayB);
						}
						return compare(a.tijd, b.tijd);
					})
			);
		},
		sortedVestigingen() {
			return [...this.vestigingen].sort((a, b) => {
				if (a.naam > b.naam) {
					return 1;
				}
				if (a.naam < b.naam) {
					return -1;
				}
				return 0;
			});
		},
	},
	created() {
		this.getGroepsles(this.id);
		this.getTrainers();
		this.getVestigingen();
	},
	methods: {
		...mapActions("overlay", ["openOverlay"]),
		...mapActions("modal", ["openModal"]),
		...mapActions("groepsles", ["getGroepsles"]),
		...mapActions("rooster", ["getLes", "getRooster"]),
		...mapActions("trainer", ["getTrainers"]),
		...mapActions("vestiging", ["getVestigingen"]),
		getTrainerById(id) {
			if (!this.trainers.length) return {};
			return this.trainers.find((trainer) => trainer.id === id) || {};
		},
		getVestigingById(id) {
			if (!this.vestigingen.length) return {};
			return this.vestigingen.find((vestiging) => vestiging.id === id) || {};
		},
		getZaalById(vestigingId, zaalId) {
			if (!this.vestigingen.length) return {};
			return this.getVestigingById(vestigingId).zalen.find((zaal) => zaal.id === zaalId) || {};
		},
		getEndTime(startTime, duration) {
			return moment(startTime, "H:mm").add(duration, "minutes").format("H:mm");
		},
		getTimesLeft(startWeek, endWeek, day) {
			const thisWeek = moment().week();
			const thisDay = moment().day();
			const extraDay = thisDay > this.days.indexOf(day) ? -1 : 0;

			return endWeek - thisWeek + extraDay;
		},
		openLesEdit(les) {
			this.openOverlay({
				name: "groepsles-inplannen",
				data: { les, editMode: true },
			});
		},
	},
};
</script>

<style lang="scss">
$columns: (
	dag: (
		width: 11%,
	),
	tijd: (
		width: 13%,
	),
	locatie: (
		width: 14%,
	),
	zaal: (
		width: 11%,
	),
	plaatsen: (
		width: 10%,
	),
	coach: (
		width: 18%,
	),
	buttons: (
		width: 16%,
	),
	type: (
		width: 7%,
	),
);

@mixin generate-columns {
	@each $label, $column in $columns {
		[data-t="header-#{$label}"] {
			padding: 0 calc($default-padding / 2);
			@include breakpoint(medium) {
				flex: 0 0 map-get($column, width);
				max-width: map-get($column, width);
			}
		}
		[data-t="item-#{$label}"] {
			padding: 0 calc($default-padding / 2);
			@include breakpoint(medium) {
				flex: 0 0 map-get($column, width);
				max-width: map-get($column, width);
			}
		}
	}
}

[data-t="item-type"] {
	.svg-container {
		font-size: 1.4rem;
		display: flex;
		align-items: center;
		svg {
			width: 1em;
			height: 1em;
		}
	}
}

@include generate-columns();

[data-t="item-buttons"] {
	display: flex;
	align-self: flex-end;
	justify-content: flex-end;
	button {
		margin: 0;
	}
}

[data-t="item-plaatsen"] {
	text-align: center;
}

.pg_header_lesson_description {
	img {
		max-width: 25%;
		margin-right: 20px;
		margin-bottom: 20px;
		display: block;
		float: left;
	}
	color: $brand-primary-lighter;
}

.pg_vestiging-select {
	display: inline-block;
}
</style>
